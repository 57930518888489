import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import {
  CountryCodeWithoutUnknownType,
  getCountryCodeFromLocale,
} from '@constants/internationalConstants';

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    if (!ref) {
      return;
    }
    ref.current = value;
  });
  return ref.current;
};

export enum Method {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
}

const getFetchInstance = (
  url: string,
  method?: Method,
  body?: Record<string, unknown>,
): (() => Promise<Response>) | null => {
  if (method === Method.GET || !method) {
    return async () => fetch(url);
  } else if ([Method.PUT, Method.POST].includes(method)) {
    return async () =>
      fetch(url, {
        method,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
  }
  return null;
};

export const useFetch = <D>(): {
  callFetch: (
    url: string,
    method?: Method | undefined,
    body?: Record<string, unknown>,
  ) => Promise<void>;
  data: D | null;
  isFetching: boolean;
  error: string | null;
} => {
  const [data, setData] = useState<D | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const callFetch = async (
    url: string,
    method?: Method,
    body?: Record<string, unknown>,
  ): Promise<void> => {
    if (isFetching) {
      return;
    }
    try {
      setError(null);
      setIsFetching(true);

      const fetchInstance = getFetchInstance(url, method, body);

      if (!fetchInstance) {
        setIsFetching(false);
        throw new Error('No fetch instance found');
      }

      const response = await fetchInstance();

      if (!response.ok) {
        const e = await response.text();
        throw new Error(JSON.parse(e).message);
      }

      const responseData = await response.json();
      setData(responseData);
    } catch (e) {
      setError((e as Error).message);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    callFetch,
    data,
    isFetching,
    error,
  };
};

export const useDebounce = (
  value: string | null | number,
  timeout: number,
): typeof value => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

/**
 * This hook returns a countryCode from the current page locale
 *
 * e.g. If you are on www.secondnature.io, the countryCode will be returned as GB
 *
 * Whereas, if you are on www.secondnature.io/au, the countryCode will be returned as AU
 */
export const useCountryCodeFromLocale = (): CountryCodeWithoutUnknownType => {
  const { locale } = useRouter();
  return getCountryCodeFromLocale(locale);
};

/**
 * If the user's locale is UK or unknown, we'll show them GLP1 content. If it's anything
 * else, we want to hide all GLP1 content
 *
 * @returns boolean determining whether a user should see GLP1 content on the website
 */
export const useCanViewGLP1Content = (): boolean => {
  const { locale } = useRouter();
  return !locale || locale === 'uk';
};

// Nice explainer for this on Dan Abramov's blog:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (
  callback: () => void,
  delay: number | null,
): void => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};
