import { ReactNode } from 'react';

import colours from './colours';

type BodyTextType = {
  children: ReactNode;
  fontSize: number;
  fontWeight: number;
  letterSpacing?: number;
  float: 'none' | 'left' | 'right';
  hasTextShadow: boolean;
  colour: string;
  textAlign: 'left' | 'center' | 'right';
  textTransform: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
  maxWidth?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  strikeThrough: boolean;
  withArrow: boolean;
  shinkTextOnMobile: boolean;
  customStyles: {
    [key: string]: string | number;
  };
  centerOnMobile?: boolean;
};

const BodyText = ({
  children,
  fontSize,
  fontWeight,
  letterSpacing,
  float,
  hasTextShadow,
  colour,
  textAlign,
  textTransform,
  maxWidth,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  strikeThrough,
  withArrow,
  shinkTextOnMobile,
  customStyles,
  centerOnMobile,
}: BodyTextType): JSX.Element => (
  <>
    <p style={customStyles}>{children}</p>
    <style jsx>{`
      p {
        font-weight: ${fontWeight};
        font-size: ${fontSize}px;
        float: ${float};
        line-height: 1.428571429;
        margin: 0 0 10px;
        color: ${colour};
        text-shadow: ${hasTextShadow ? '0 4px 4px rgba(0, 0, 0, 0.2)' : 'none'};
        text-align: ${textAlign};
        text-transform: ${textTransform};
        max-width: ${maxWidth !== undefined ? `${maxWidth}px` : 'none'};
        margin-left: ${maxWidth !== undefined ? 'auto' : 0};
        margin-right: ${maxWidth !== undefined ? 'auto' : 0};
        ${marginTop !== undefined ? `margin-top: ${marginTop}px` : ''};
        ${marginBottom !== undefined ? `margin-bottom: ${marginBottom}px` : ''};
        ${marginLeft !== undefined ? `margin-left: ${marginLeft}px` : ''};
        ${marginRight !== undefined ? `margin-right: ${marginRight}px` : ''};
        ${strikeThrough ? `text-decoration: line-through` : ''};
        ${letterSpacing !== undefined
          ? `letter-spacing: ${letterSpacing}px`
          : ''};
      }

      p:after {
        ${withArrow ? `content: '\\2192'` : ''};
        ${withArrow ? `padding-left: 1em` : ''};
      }

      @media (max-width: 767px) {
        p {
          font-size: ${shinkTextOnMobile ? fontSize / 1.2 : fontSize}px;
          text-align: ${centerOnMobile ? 'center' : textAlign};
        }
      }
    `}</style>
  </>
);

BodyText.defaultProps = {
  fontSize: 15,
  fontWeight: 400,
  float: 'none',
  hasTextShadow: false,
  colour: colours.snBlack,
  textAlign: 'left',
  textTransform: 'none',
  strikeThrough: false,
  withArrow: false,
  shinkTextOnMobile: true,
  customStyles: {},
  centerOnMobile: false,
};

type H2Type = {
  children: ReactNode;
  textAlign: 'left' | 'center' | 'right';
  noMargin?: boolean;
  color: string;
  fontSize: number;
  fontSizeDesktop: number;
};

const H2 = ({
  children,
  textAlign,
  noMargin,
  color,
  fontSize,
  fontSizeDesktop,
}: H2Type): JSX.Element => (
  <>
    <h2>{children}</h2>
    <style jsx>{`
      h2 {
        font-weight: 900;
        font-size: ${fontSizeDesktop}px;
        color: ${color};
        line-height: 1.1;
        letter-spacing: -2px;
        text-align: ${textAlign};
        margin: ${noMargin ? 0 : `0 0 21px`};
      }

      @media (max-width: 767px) {
        h2 {
          font-size: ${fontSize}px;
          line-height: 1.4;
          letter-spacing: -0.34px;
          margin: ${noMargin ? 0 : `0 0 10px`};
        }
      }
    `}</style>
  </>
);

H2.defaultProps = {
  textAlign: 'left',
  noMargin: false,
  color: colours.snBlack,
  fontSize: 22,
  fontSizeDesktop: 50,
};

type H3Type = {
  children: ReactNode;
  textAlign: 'left' | 'center' | 'right' | 'inherit';
  colour: string;
  marginBottom: number;
  marginTop: number;
  fontSize: number;
  fontSizeDesktop: number;
};

const H3 = ({
  children,
  textAlign,
  colour,
  marginBottom,
  marginTop,
  fontSize,
  fontSizeDesktop,
}: H3Type): JSX.Element => (
  <>
    <h3>{children}</h3>
    <style jsx>{`
      h3 {
        font-weight: 900;
        font-size: ${fontSizeDesktop}px;
        color: ${colour};
        line-height: 1.38;
        letter-spacing: -0.41px;
        text-align: ${textAlign};
        margin: ${marginTop}px 0 ${marginBottom}px 0;
      }

      @media (max-width: 767px) {
        h3 {
          font-size: ${fontSize}px;
          line-height: 1.4;
          letter-spacing: -0.34px;
          margin: ${marginTop} 0 ${marginBottom}px 0;
        }
      }
    `}</style>
  </>
);

H3.defaultProps = {
  textAlign: 'left',
  marginBottom: 16,
  marginTop: 0,
  colour: colours.snBlack,
  fontSize: 22,
  fontSizeDesktop: 26,
};

type H4Type = {
  children: ReactNode;
  textAlign: 'left' | 'center' | 'right';
};

const H4 = ({ children, textAlign }: H4Type): JSX.Element => (
  <>
    <h4>{children}</h4>
    <style jsx>{`
      h4 {
        font-weight: 900;
        font-size: 18px;
        color: ${colours.snBlack};
        line-height: 1.72;
        letter-spacing: -0.25px;
        text-align: ${textAlign};
        margin: 0 0 1px;
      }

      @media (max-width: 767px) {
        h4 {
          line-height: 1.63;
          font-size: 16px;
          letter-spacing: -0.22px;
          margin: 0;
        }
      }
    `}</style>
  </>
);

H4.defaultProps = {
  textAlign: 'left',
};

type PType = {
  children: ReactNode;
  marginTop: number;
  marginBottom: number;
  textAlign: 'left' | 'center' | 'right' | 'justify' | 'initial' | 'inherit';
  color: string;
  fontWeight: 400 | 600 | 900;
  fontSize: number;
  lineHeight: number;
  fontStyle: string;
  centerOnDesktop?: boolean;
  scaleOnMobile: boolean;
  // Used for Cypress tests
  dataCy?: string;
};

const P = ({
  children,
  marginTop,
  marginBottom,
  textAlign,
  color,
  fontWeight,
  fontSize,
  lineHeight,
  fontStyle,
  centerOnDesktop,
  scaleOnMobile,
  dataCy,
}: PType): JSX.Element => (
  <>
    <p data-cy={dataCy}>{children}</p>
    <style jsx>{`
      p {
        font-size: ${fontSize * (scaleOnMobile ? 1.25 : 1)}px;
        line-height: ${lineHeight};
        letter-spacing: -0.25px;
        color: ${color};
        margin: ${marginTop}px 0 ${marginBottom}px;
        font-weight: ${fontWeight};
        text-align: ${textAlign};
        font-style: ${fontStyle};
        text-align: ${centerOnDesktop ? 'center' : textAlign};
      }

      @media (max-width: 767px) {
        p {
          font-size: ${fontSize}px;
          text-align: ${textAlign};
        }
      }
      p > :global(strong) {
        font-weight: 900;
      }
    `}</style>
  </>
);

P.defaultProps = {
  marginTop: 5,
  marginBottom: 10,
  textAlign: 'initial',
  color: colours.snBlack,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 1.428571429,
  fontStyle: 'normal',
  scaleOnMobile: true,
};

export { P, H2, H3, H4, BodyText };
